import React,{useEffect} from 'react'
import Layout from '../Layout.jsx'
import RegistrarseLayout from '../../Components/RegistrarseLayout/RegistrarseLayout.jsx'
import { useNavigate } from 'react-router-dom'
const Registrarse= () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <RegistrarseLayout/>
    </Layout>
  )
}

export default Registrarse
