import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/customBox.css'; // Importar el archivo CSS

const CustomBox = ({ number, numberColor, boxColor, textColor, title, description }) => {
  return (
    <div className="custom-box" style={{ backgroundColor: boxColor }}>
      <div className="number-circle" style={{ backgroundColor: numberColor }}>
        {number}
      </div>
      <div className="content">
        <h4 style={{ color: textColor }}>{title}</h4>
        <p style={{ color: textColor }} dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
    </div>
  );
};

CustomBox.propTypes = {
  number: PropTypes.number.isRequired,
  numberColor: PropTypes.string.isRequired,
  boxColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CustomBox;
