import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "../../styles/DonationBox.css"
import DonationBox from './DonationBox';
import DonacionAbierta from './OpenDonation';

const DonacionLayout = () => {
  const [currentGroup, setCurrentGroup] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const donationBoxes = [
    { amount: 20, status: "Donacion Cerrada" },
    { amount: 50, status: "Donacion Cerrada" },
    { amount: 100, status: "Donacion Cerrada" },
    { amount: 150, status: "Donacion Cerrada" },
    { amount: 200, status: "Donacion Cerrada" },
    { amount: 300, status: "Donacion Cerrada" },

    { type: 'abierta' },  // Este será nuestro componente de donación abierta
  ];

  const groupCount = Math.ceil(donationBoxes.length / 3);

  const nextGroup = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentGroup((prevGroup) => (prevGroup + 1) % groupCount);
    }
  };

  const prevGroup = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentGroup((prevGroup) => (prevGroup - 1 + groupCount) % groupCount);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTransitioning(false);
    }, 300);

    return () => clearTimeout(timer);
  }, [currentGroup]);

  const getCurrentGroupBoxes = () => {
    const startIndex = currentGroup * 3;
    return donationBoxes.slice(startIndex, startIndex + 3);
  };

  return (
    <div className='container-donation'>
      <h1 className='titulo'>Donacion Cerrada</h1>
      <div className='contenedor-donaciones1'>
        <div className={`carousel-container ${isTransitioning ? 'transitioning' : ''}`}>
          {getCurrentGroupBoxes().map((box, index) => (
            <div key={index} className='carousel-item'>
              {box.type === 'abierta' ? (
                <DonacionAbierta />
              ) : (
                <DonationBox
                  status={box.status}
                  amount={box.amount}
                  buttonText="Donar"
                  boxColor="#fff"
                  textColor="#000"
                  buttonColor="#d3d3d3"
                  buttonTextColor="#000"
                />
              )}
            </div>
          ))}
        </div>
        <button className="carousel-button prev" onClick={prevGroup}>&lt;</button>
        <button className="carousel-button next" onClick={nextGroup}>&gt;</button>
      </div>
      <Link to='/donacion_a_vs_c'><p>Donacion abierta vs Donacion cerrada</p></Link>
    </div>
  );
}

export default DonacionLayout;
