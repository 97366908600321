import React,{useEffect} from 'react'
import Layout from '../../Layout.jsx'
import Estudiantes from '../../../Components/Proyectos/Estudiantes.jsx'
import { useNavigate } from 'react-router-dom'
const EstudiantesL= () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <Estudiantes/>
    </Layout>
  )
}

export default EstudiantesL
