import React from 'react';
import Navbar from '../Components/ComponentesGenerales/Navbar.jsx'
const Layout = ({ children }) => {


  return (
    <React.Fragment>
      <Navbar/>
        <div className="container">
          <main>{children}</main>
        </div>
    </React.Fragment>
  );
};

export default Layout;
