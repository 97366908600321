import React,{useEffect} from 'react'
import Layout from '../../Layout.jsx'
import OculusLayout from "../../../Components/Proyectos/OculusLayout.jsx"
import { useNavigate } from 'react-router-dom'
const Oculus= () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <OculusLayout/>
    </Layout>
  )
}

export default Oculus
