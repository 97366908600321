import React from 'react';
import '../../styles/DonationInformation.css'; // Asegúrate de crear este archivo CSS
import donaC from "../../assets/images/donaCer.jpg"
const DonationInformation = () => {
  return (
    <div className='container-donationInformation'>
      <h1 className='titulo1-infomacion'>Tipo de Donaciones</h1>
      <div className='columns'>
        <div className='card-container'>
          <div className='card'>
            <div className='card-front img-abierta'>
              <div className="card-title">
              <h3>Donación Abierta</h3>

              </div>
              
            </div>
            <div className='card-back'>
            <p>
                La donación abierta te brinda la libertad de<br/>
                elegir la cantidad exacta que deseas donar.<br/>
                Esto significa que tú, como donante, decides<br/>
                el monto que consideras apropiado<br/>
                y significativo para apoyar la causa que te<br/>
                interesa. Esta opción es ideal si prefieres<br/>
                tener control total sobre la cantidad de tu<br/>
                contribución y adaptarla según tus<br/>
                posibilidades financieras o tu nivel de<br/>
                compromiso con la causa.
              </p>
            </div>
          </div>
        </div>
        <div className='card-container'>
          <div className='card'>
            <div className='card-front img-cerrada'>
            <div className="card-title">
            <h3>Donación Cerrada</h3>

            </div>
            </div>
            <div className='card-back'>
            <p>
                Por otro lado, la donación cerrada establece<br/>
                un monto específico predeterminado por la<br/>
                fundación u organización receptora. En este<br/>
                caso, como donante, tienes la opción de<br/>
                seleccionar entre diferentes montos<br/>
                predefinidos para donar. Esta modalidad<br/>
                puede ser conveniente si prefieres que se te<br/>
                guíe en cuanto a la cantidad a donar o si<br/>
                deseas asegurarte de que tu contribución se<br/>
                alinee con las necesidades específicas de la<br/>
                organización beneficiaria.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationInformation;
