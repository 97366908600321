import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import "../../styles/navbarStyle.css";

const NavBar = () => {
  const [activeItem, setActiveItem] = useState('');
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const submenuRef = useRef(null);

  const handleClick = (item) => {
    setActiveItem(item);
  };

  const toggleSubmenu = () => {
    setIsSubmenuOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setIsSubmenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <NavLink
            className={({ isActive }) => `navbar-item ${isActive ? 'is-active' : ''}`}
            to="/inicio"
            onClick={() => handleClick('INICIO')}
          >
            INICIO
          </NavLink>

          <NavLink
            className={({ isActive }) => `navbar-item ${isActive ? 'is-active' : ''}`}
            to="/donaciones"
            onClick={() => handleClick('DONACIONES')}
          >
            DONACIONES
          </NavLink>

          <NavLink
            className={({ isActive }) => `navbar-item ${isActive ? 'is-active' : ''}`}
            to="/proyectos"
            onClick={() => handleClick('PROYECTOS')}
          >
            PROYECTOS
          </NavLink>

          <div className={`navbar-item has-dropdown ${isSubmenuOpen ? 'is-active' : ''}`} ref={submenuRef}>
            <button className="navbar-link" onClick={toggleSubmenu}>
              ACCESO 
            </button>

            <div className={`navbar-dropdown ${isSubmenuOpen ? 'is-active' : ''}`}>
              <NavLink
                className={({ isActive }) => `navbar-item ${isActive ? 'is-active' : ''}`}
                to="/login"
                onClick={() => handleClick('LOGIN')}
              >
                LOG IN
              </NavLink>
              <NavLink
                className={({ isActive }) => `navbar-item ${isActive ? 'is-active' : ''}`}
                to="/registro"
                onClick={() => handleClick('REGISTRO')}
              >
                REGISTRO
              </NavLink>
              
            </div>
          </div>
          <NavLink
            className={({ isActive }) => `navbar-item ${isActive ? 'is-active' : ''}`}
            to="/estudiantes"
            onClick={() => handleClick('AYUDA A ESTUDIANTES')}
          >
            AYUDA A ESTUDIANTES
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
