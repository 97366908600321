import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/DonationBox.css'; // Asegúrate de crear este archivo para los estilos CSS

const DonationBox = ({ status, amount, buttonText, boxColor, textColor, buttonColor, buttonTextColor }) => {
  return (
    <div className="donation-box" style={{ backgroundColor: boxColor }}>
      <h4 style={{ color: textColor }}>{status}</h4>
      <h1 style={{ color: textColor }}>{amount} $</h1>
      <button className="donate-button" style={{ backgroundColor: buttonColor, color: buttonTextColor }}>
        {buttonText}
      </button>
    </div>
  );
};

DonationBox.propTypes = {
  status: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  buttonText: PropTypes.string.isRequired,
  boxColor: PropTypes.string,
  textColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
};

DonationBox.defaultProps = {
  boxColor: '#fff',
  textColor: '#000',
  buttonColor: '#ccc',
  buttonTextColor: '#000',
};

export default DonationBox;
