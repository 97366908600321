import React from 'react'
import "../../styles/estudiantesStyle.css"
import { Link, useNavigate } from "react-router-dom";
import estudiantes from "../../assets/images/estudiantes.webp"

const Estudiantes = () => {
    const handleSiguienteClick=()=>{
        console.log('Hola perro')
    
    }
  return (
    <div className="container-xiaomi">
        <div className="columns-xiaomi">
          <div className="column-xiaomi columna1">
            <div className="container-img-perro">
                <img src={estudiantes} alt="logoPerro"  className='perroLogo'/>
            </div>
          </div>
          <div className="column-xiaomi columna2">
            <div className="texto-xiaomi">
                <h3>Estudiantes</h3>
                <p>
                Fomentar la colaboración y el apoyo entre <br/>
                estudiantes, maestros y la comunidad es <br/>
                esencial para proporcionar los materiales <br/>
                educativos necesarios. La solidaridad y el<br/>
                esfuerzo conjunto aseguran que todos los <br/>
                estudiantes, independientemente de sus <br/>
                circunstancias, puedan acceder a una<br/>
                educación de calidad y desarrollar su<br/>
                máximo potencial.

                </p>
                <button onClick={handleSiguienteClick} className='button is-primary buttonperro' >Donar</button>
            </div>
           
          </div>
          
        </div>
    </div>
  )
}


export default Estudiantes
