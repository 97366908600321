import React from 'react';
import imagen1 from '../../assets/images/foto1.webp';
import '../../styles/inicioStyle.css'; // Asegúrate de importar el archivo CSS
import CustomBox from '../ComponentesGenerales/CustomBox';

const InicioLayout = () => {
  return (
    <div className="containerL">
      <img className='foto1' src={imagen1} alt="foto1" />
      <div className="container-textoL">
        <p>Tu apoyo puede allanar el camino hacia un futuro 
          brillante <br/>para los jóvenes del Colegio San Agustín, 
          superando las <br/>barreras financieras que enfrentan en su educación.</p>
      </div>
      <div className='container-frase1 animated-slide'>
        <CustomBox
          number={1}
          numberColor="#FF4500"
          boxColor="#FFCB3E"
          textColor="#111111"
          title="Regístrate como Donante:"
          description={`Completa el formulario <br/> de registro del sitio web`}
        />
      </div>

      <div className='container-frase2 animated-slide'>
        <CustomBox
          number={2}
          numberColor="#FF4500"
          boxColor="#C3EAE1"
          textColor="#111111"
          title="Realiza la Donación:"
          description={`Completa los detalles de tu tarjeta <br/>
             de crédito o débito en la  <br/>
             plataforma segura de o realiza una  <br/>
             transferencia bancaria según las instrucciones proporcionadas. <br/>
              Confirma y Envía tu Donación: Revisa los detalles de tu donación, <br/> 
              confirma la transacción y envía el depósito según las indicaciones de <br/>FESA. `}
        />
      </div>
      <div className='container-frase3 animated-slide'>
        <CustomBox
          number={3}
          numberColor="#FF4500"
          boxColor="#C0BFD9"
          textColor="#111111"
          title="Recibe Confirmación y Recibo: "
          description={`
            FESA te enviará una<br/>
             confirmación de la <br/>
             donación y un recibo por <br/>correo electrónico y en 
             <br/>pantalla.
            
            `}
        />
      </div>
    </div>
  );
}

export default InicioLayout;
