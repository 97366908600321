import React, { useState } from 'react';
import "../../styles/DonationBox.css";

const DonacionAbierta = () => {
  const [amount, setAmount] = useState('');

  const handleChange = (e) => {
    setAmount(e.target.value);
  };

  const handleDonate = () => {
    // Lógica para manejar la donación
    alert(`Donaste $${amount}`);
  };

  return (
    <div className="donacion-abierta">
      <h2>Donacion Abierta</h2>
      <h3>Elige un monto</h3>
      <div className="monto-input">
        
        <input 
          type="number" 
          value={amount} 
          onChange={handleChange} 
          placeholder="Monto" 
        />
        <span className='dolar'>$</span>
      </div>
      <button onClick={handleDonate}>Donar</button>
    </div>
  );
};

export default DonacionAbierta;
