import React,{useEffect} from 'react'
import Layout from '../Layout.jsx'
import DonacionLayout from '../../Components/Donaciones/DonationLayout.jsx'
import { useNavigate } from 'react-router-dom'
const Donacion = () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <DonacionLayout/>
    </Layout>
  )
}

export default Donacion
