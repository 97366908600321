import React,{useEffect} from 'react'
import Layout from '../Layout.jsx'
import AccederLayout from '../../Components/AccederLayout/AccederLayout.jsx'
import { useNavigate } from 'react-router-dom'
const LogIn= () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <AccederLayout/>
    </Layout>
  )
}

export default LogIn
