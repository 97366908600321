import React from 'react';
import "../../styles/registrarseStyle.css";
import logoCorazoncito from "../../assets/images/corazon.webp";
import conFesa from "../../assets/images/conFesa.webp";
import somosUno from "../../assets/images/somosUno.webp";
const RegistrarseLayout = () => {
  return (
    <div className='container-registro'>
      <div className='columns-registrarse'>
        <div className='column-registrarse columna1'>
          <h1>Registrarse</h1>
          <div className='input-field'>
            <label>Nombre</label>
            <input type="text" className='input-registrarse' />
          </div>
          <div className='input-field'>
            <label>Apellido</label>
            <input type="text" className='input-registrarse' />
          </div>
          <div className='input-field'>
            <label>Contraseña</label>
            <input type="password" className='input-registrarse' />
          </div>
          <div className='input-field'>
            <label>Confirmar Contraseña</label>
            <input type="password" className='input-registrarse' />
          </div>
          <div className='input-field'>
            <label>E-mail</label>
            <input type="email" className='input-registrarse' />
          </div>
          <div className='input-field'>
            <label className="checkbox-label">
              <input type="checkbox" className="custom-checkbox" />
              Recordarme
            </label>
          </div>
          <button type='submit' className='button is-primary registrarse'>Registrarse</button>
        </div>
        <div className="column-registrarse columna2">
          <img src={logoCorazoncito} alt="Logo Corazoncito" className='logoH' />
          <img className="conFesaR" src={conFesa} alt="conFesa" />
          <img className="somosUnoR" src={somosUno} alt="somosUno" />
        </div>
      </div>
    </div>
  )
}

export default RegistrarseLayout;
