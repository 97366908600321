import Modal from "react-modal"
import React from 'react'
import "../../styles/modalStyle.css"
const Modales = ({closeModal}) => {

    return (
    <div className="background-modal">
          <div className="container-modal">
            <button className="xModal" onClick={()=>closeModal(false)}>X</button>
            <div className="title">
                <h1 className="titulo">Tu Suscripción nos importa</h1>
                <hr className="title-underline" />
                </div>
            <div className="contenedor-fields">
                <p>Al registrarte te enviareamos datos interesantes de la fundación<br/>
                Recordarte que la suscripcion es totalmente <strong>gratuita</strong> </p>
                <form>
                <div className="field">
                    <label className="label">Nombre :</label>
                    <div className="control">
                        <input
                            type="text"
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Email :</label>
                    <div className="control">
                        <input
                             type="email"
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Teléfono :</label>
                    <div className="control">
                        <input
                             type="number"
                            className="input"
                            required
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Pais :</label>
                    <div className="control">
                        <input
                             type="text"
                            className="input"
                            required
                        />
                    </div>
                </div>
                <button  className="button is-primary siguiente3">Suscribirse</button>
                </form>
            </div>
          </div>
    </div>
  )
}

export default Modales

