import {BrowserRouter,Routes,Route} from "react-router-dom"
import LandingPage from "./Components/LandingPage/LandingPageLayout.jsx"
import Inicio from "./pages/paginas/Inicio.jsx"
import Registrarse from "./pages/paginas/Registrarse.jsx";
import LogIn from "./pages/paginas/LogIn.jsx";
import Donacion from "./pages/paginas/Donation.jsx" 
import DonacionInformation from "./pages/paginas/DonationAbvsCl.jsx";
import Oculus from "./pages/paginas/Proyectos/Oculus.jsx";
import Metaverso from "./pages/paginas/Proyectos/Metaverso.jsx";
import Proyectos from "./pages/paginas/Proyectos.jsx";
import Xiaomi from "./pages/paginas/Proyectos/XiaomiDog.jsx";
import EstudiantesL from "./pages/paginas/Proyectos/EstudiantesL.jsx";
function App() {
  return (
    <div className='contenedor'> 
     <BrowserRouter>
      <Routes>
          <Route path='/' element={<LandingPage/>}/>
          <Route path='/inicio' element={<Inicio/>}/>
          <Route path='/registro' element={<Registrarse/>}/>
          <Route path='/login' element={<LogIn/>}/>
          <Route path='/donaciones' element={<Donacion/>}/>
          <Route path='/donacion_a_vs_c' element={<DonacionInformation/>}/>
          <Route path='/proyectos' element={<Proyectos/>}/>
          <Route path='/oculus' element={<Oculus/>}/>
          <Route path='/metaverso' element={<Metaverso/>}/>
          <Route path='/xiaomi' element={<Xiaomi/>}/>
          <Route path='/estudiantes' element={<EstudiantesL/>}/>
      </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
