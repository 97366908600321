import React, { useState, useEffect, Suspense, useRef } from 'react';
import '../../styles/Oculus.css';
import { Canvas, useFrame } from '@react-three/fiber';
import { Stars, Cloud, Environment, OrbitControls, useGLTF,PerspectiveCamera } from '@react-three/drei';
import { EffectComposer, Bloom, Noise, Vignette } from '@react-three/postprocessing';

const Model = ({ modelPath }) => {
  const { scene } = useGLTF(modelPath);
  const modelRef = useRef();

  useFrame((state) => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.005; // Rotación suave
    }
  });

  return (
    <primitive 
      ref={modelRef}
      object={scene} 
      scale={[10, 10,10]} // Aumentado la escala
      position={[0, 0, 0]} // Movido más cerca de la cámara
    />
  );
};

const Oculus = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModelSection, setShowModelSection] = useState(false);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    let player;

    window.onYouTubeIframeAPIReady = () => {
      player = new window.YT.Player('youtube-player', {
        height: '100%',
        width: '100%',
        videoId: 'KLOcj5qvOio',
        playerVars: {
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          fs: 0,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          start: 20,
          end: 60,
          loop: 1,
          mute: 1,
          playlist: 'KLOcj5qvOio'
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange
        }
      });
    };

    const onPlayerReady = (event) => {
      event.target.playVideo();
    };

    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        player.seekTo(0);
        player.playVideo();
      }
    };

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, []);

  const handleScrollToModel = () => {
    setShowModelSection(true);
    setTimeout(() => {
      const modelSection = document.getElementById('model-section');
      if (modelSection) {
        window.scrollTo({
          top: modelSection.offsetTop,
          behavior: 'smooth'
        });
      }
    }, 100);
  };

  return (
    <div className="oculus-container">
      <div className="video-background">
        <div id="youtube-player"></div>
      </div>
      <div
        className={`content-overlay ${showOverlay ? 'show' : ''}`}
        onMouseEnter={() => setShowOverlay(true)}
        onMouseLeave={() => setShowOverlay(false)}
      >
        <div className={`overlay-content ${showOverlay ? 'show' : ''}`}>
          <h1 className="text">Explora la Realidad Virtual con Oculus</h1>
          <p className="text">
            El oculus ofrece un aprendizaje inmersivo e interactivo, donde los estudiantes pueden explorar entornos históricos, científicos y geográficos de manera práctica. Además, facilita el acceso a recursos globales y la colaboración en tiempo real, desarrollando habilidades digitales avanzadas. Su entorno personalizado promueve la inclusividad, aumentando la motivación y el interés de los estudiantes por el aprendizaje.
          </p>
          <button onClick={handleScrollToModel} className="button">Pruébalo</button>
        </div>
      </div>
      {showModelSection && (
        <div id="model-section" className="model-section">
        <Canvas style={{ width: '100%', height: '100vh' }}>
          <PerspectiveCamera makeDefault position={[0, 0, 3.8]} fov={56} />
          {/* <color attach="background" args={['#001']} /> */}
          {/* <fog attach="fog" args={['#070710', 1, 10]} /> */}
          <ambientLight intensity={0.5} />
          <spotLight position={[8, 8, 8]} angle={0.15} penumbra={1} intensity={2} />
          
          <Suspense fallback={null}>
            <Model modelPath='/models/oculus.glb' />
            <Stars radius={50} depth={50} count={5000} factor={4} saturation={0} fade speed={1} />
            <Cloud opacity={0.5} speed={0.4} width={10} depth={1.5} segments={20} />
            <Environment preset="studio" />
          </Suspense>
          
          <OrbitControls enableZoom={true} enablePan={false} />
        </Canvas>

          <div className="donation-section">
            <h2 className="text">Apoya el Proyecto</h2>
            <p className="text">Si te ha gustado lo que has visto y quieres apoyar el desarrollo, puedes hacer una donación.</p>
            <a href="link_to_donation" className="button">Donar</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Oculus;