import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../../styles/landingPageStyle.css';
import logo from "../../assets/images/fesaLogo.png";
import corazon from "../../assets/images/corazon.webp";
import conFesa from "../../assets/images/conFesa.webp";
import somosUno from "../../assets/images/somosUno.webp";
import Modales from "../ComponentesGenerales/Modal.jsx";

const LandingPage = () => {
  const pagina1Ref = useRef(null);
  const pagina2Ref = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fraseinferior = document.querySelector('.fraseinferior');
    const totalImageAnimationTime = 6000;

    setTimeout(() => {
      fraseinferior.classList.add('finished');
    }, totalImageAnimationTime);

    // Aparecer el modal después de 5 segundos
    const modalTimer = setTimeout(() => {
      setOpenModal(true);
    }, 3500);

    return () => clearTimeout(modalTimer); // Limpiar el temporizador cuando el componente se desmonte
  }, []);

  const scrollToSection = (elementRef) => {
    elementRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSiguienteClick = () => {
    navigate('/inicio');
  };

  return (
    <div className="hero-body">
      <div ref={pagina1Ref} className="container pagina1">
        <img className="logoFesa" src={logo} alt="logo" />
        <img className="heart-image" src={corazon} alt="Corazón latiendo" />
        <div className="container-frases">
          <h3 className="frasesuperior">Plataforma de Donaciones</h3>
          <img className="conFesa" src={conFesa} alt="conFesa" />
          <img className="somosUno" src={somosUno} alt="somosUno" />
          <h3 className="fraseinferior">Mentes brillantes, corazones generosos</h3>
          <button onClick={() => scrollToSection(pagina2Ref)} className="button is-primary siguiente1">
            Siguiente
          </button>
        </div>
      </div>
      <div ref={pagina2Ref} className="container pagina2">
        <img className="logoFesaa" src={logo} alt="logo" />
        <div className="container-texto texto1">
          <p>
            <h3>FESA</h3>
            Es una organización Boliviana sin <br/>fines de lucro enfocada en la<br/>
            educación y formación integral de<br/> jóvenes, promoviendo habilidades<br/>
            STEM y valores éticos
          </p>
        </div>
        <div className="container-texto texto2">
          <p>
            <h3 className="titulo2">Misión e Impacto</h3><br/>
            Nuestra misión es transformar vidas a <br/>
            través de la educación. Ofrecemos <br/>
            programas, formación docente y mejoras<br/>
            en infraestructura educativa, impactando <br/>
            positivamente en las comunidades.
          </p>
        </div>
        <div className="container-texto texto3">
          <p>
            <h3 className="titulo3">¿Por Qué Donar?</h3><br/>
            Tu donación ayuda a construir y <br/>
            renovar escuelas, proporcionar <br/>
            materiales educativos y ofrecer <br/>
            becas. Con tu apoyo, podemos <br/>
            continuar expandiendo nuestro <br/>
            alcance y creando un futuro <br/>
            mejor para los jóvenes.<br/>
          </p>
        </div>
        <button onClick={handleSiguienteClick} className="button is-primary siguiente2">Siguiente</button>
        {openModal && <Modales closeModal={setOpenModal} />}
      </div>
    </div>
  );
}

export default LandingPage;
