import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import "../../styles/ProjectsStyle.css";
import perroImg from '../../assets/images/perro.jpg';
import aulaImg from '../../assets/images/aula.jpg';
import lentesImg from '../../assets/images/lentes.jpg';
// import personasImg from '../../assets/images/personas.jpg';

const projects = [
  {
    title: "Xiaomi Cyber dog",
    image: perroImg,
    link: "/xiaomi"
  },
  {
    title: "Aula de Metaverso",
    image: aulaImg,
    link: "/metaverso"
  },
  {
    title: "Oculus",
    image: lentesImg,
    link: "/oculus"
  }
  // ,
  // {
  //   title: "Estudiantes",
  //   image: personasImg,
  //   link: "/estudiantes"
  // }
];

const ProyectosLayout = () => {
  const cardsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        });
      },
      { threshold: 0.1 }
    );

    cardsRef.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      cardsRef.current.forEach((card) => {
        if (card) observer.unobserve(card);
      });
    };
  }, []);

  return (
    <div className="projects-container">
      <h1>Proyectos para Donar</h1>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div
            key={index}
            className="project-card"
            ref={(el) => (cardsRef.current[index] = el)}
          >
            <img src={project.image} alt={project.title} />
            <h2>{project.title}</h2>
            <Link to={project.link} className="details-button">Detalles</Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProyectosLayout;
