import React,{useEffect} from 'react'
import Layout from '../Layout.jsx'
import DonationInformation from "../../Components/Donaciones/DonationInformation.jsx"
import { useNavigate } from 'react-router-dom'
const DonationAbvsCl = () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <DonationInformation/>
    </Layout>
  )
}

export default DonationAbvsCl;
