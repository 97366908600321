import React from 'react'
import "../../styles/XiaomiCyberDogStyle.css"
import { Link, useNavigate } from "react-router-dom";
import perro from "../../assets/images/perro2.webp"
const XiaomiCyberDog = () => {
    const handleSiguienteClick=()=>{
        console.log('Hola perro')
    
    }
  return (
    <div className="container-xiaomi">
        <div className="columns-xiaomi">
          <div className="column-xiaomi columna1">
            <div className="container-img-perro">
                <img src={perro} alt="logoPerro"  className='perroLogo'/>
            </div>
          </div>
          <div className="column-xiaomi columna2">
            <div className="texto-xiaomi">
                <h3>Xiaomi Cyber Dog</h3>
                <p>
                Es un proyecto para los  <br/>
                estudiantes que consiste que los<br/>
                desarollen habilidades para que<br/>
                programen el cyber dog para el<br/>
                colegio y se adapte a las<br/>
                necesidades de los estudiantes.<br/> 
                </p>
                <button onClick={handleSiguienteClick} className='button is-primary buttonperro' >Ver más..</button>
            </div>
           
          </div>
          
        </div>
    </div>
  )
}

export default XiaomiCyberDog
