import React,{useEffect} from 'react'
import Layout from '../../Layout.jsx'
import MetaverseClassroom from '../../../Components/Proyectos/AulaMetaverso.jsx'
import { useNavigate } from 'react-router-dom'
const Metaverso= () => {
    const navigate=useNavigate();
    
    
  return (
    <Layout>
      <MetaverseClassroom/>
    </Layout>
  )
}

export default Metaverso
