import React, { Suspense, useState, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF, Environment, PerspectiveCamera, Html, useTexture } from '@react-three/drei';
import { Physics, useBox, usePlane } from '@react-three/cannon';
import * as THREE from 'three';

// Componente para el aula
function Classroom({ position = [0, 0, 0] }) {
  const { scene } = useGLTF('/models/endroom.glb');
  return <primitive object={scene} position={position} />;
}

// Componente para pantalla interactiva
function InteractiveScreen({ position, rotation, onInteract }) {
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = "/videos/video.mp4";
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    vid.muted = true;
    return vid;
  });

  useEffect(() => {
   // video.play();

    return () => {
      video.pause();
    };
  }, [video]);

  const texture = new THREE.VideoTexture(video);
  
  return (
    <mesh position={position} rotation={rotation} onClick={onInteract}>
      <Html transform position={[5, 5,1]}>
        <div style={{ width: "199px", height: "95px", backgroundColor: "black" }}>
          <video
            src="/videos/video.mp4"
            style={{ width: "100%", height: "100%" }}
            controls
          />
        </div>
      </Html>
    </mesh>
  );
}

// Componente para cambiar el entorno
function EnvironmentSwitcher({ currentEnvironment, onSwitch }) {
  return (
    <Html position={[-5, 3, 0]}>
      <button onClick={onSwitch} style={{
        padding: '10px',
        fontSize: '16px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
      }}>
        Cambiar Entorno: {currentEnvironment}
      </button>
    </Html>
  );
}

// Componente para la sección de donación
function DonationSection() {
  return (
    <Html position={[5, 3, 0]}>
      <div style={{
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '20px',
        borderRadius: '10px',
        width: '300px'
      }}>
        <h2>Apoya el Aula Metaverso</h2>
        <p>Tu donación nos ayuda a mejorar la experiencia educativa en el metaverso.</p>
        <ul>
          <li>Acceso a educación de calidad para todos</li>
          <li>Experiencias inmersivas de aprendizaje</li>
          <li>Colaboración global en tiempo real</li>
        </ul>
        <button style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}>
          Donar Ahora
        </button>
      </div>
    </Html>
  );
}

// Componente principal del aula metaverso
function MetaverseClassroom() {
  const [environment, setEnvironment] = useState("sunset");
  const environments = ["sunset", "dawn", "night", "warehouse", "forest", "apartment", "studio", "city", "park", "lobby"];

  const switchEnvironment = () => {
    const currentIndex = environments.indexOf(environment);
    const nextIndex = (currentIndex + 1) % environments.length;
    setEnvironment(environments[nextIndex]);
  };

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Canvas shadows>
        <PerspectiveCamera makeDefault position={[0, 20, 0]} fov={80} />
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={180} penumbra={1} castShadow />
        
        <Physics>
          <Suspense fallback={null}>
            <Classroom position={[0, 0, 0]} />
            <InteractiveScreen 
              position={[5, -2.5, -5]} 
              rotation={[0, 4.75, 0]} 
              onInteract={() => console.log("Pantalla interactiva activada")} 
            />
            <Environment preset={environment} />
          </Suspense>

          
         

          <OrbitControls />
        </Physics>
      </Canvas>
    </div>
  );
}

export default MetaverseClassroom;